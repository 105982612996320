<template>
    <div class="content-wrapper">
        <img :src="loading" alt="img">
    </div>
</template>
<script>
import { Transit } from '@/operations/transit.js'
export default {
    name: "TransitScrollWrapper",
    computed: {
        loading () {
            return require('@/assets/images/loading.gif')
        }
    },
    created () {
        // this.init()
    },
    methods: {
        init () {
            /**
             * mode 类型 | todo 动作 ｜ info 信息
             * type
             * */
            new Transit({
                mode: 'url:internal:CheckAttendanceRuleManager',
                _Vue: this
            })
        }
    }

}
</script>
<style lang="scss" scoped>
    .content-wrapper{
        height: calc(100vh - 108px);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
    }
</style>
