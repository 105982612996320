<template>
    <div class="tree-shape" @click.stop="closeSearchPullDown">
        <DialogTitle
            :title="title"
            @closeDialog="closeDialog"
        />
        <tree-com
            :allCheck="allCheck"
            :multiple="multiple"
            :checkUserOnly="checkUserOnly"
            :checkAll="checkAll"
            :dropDownSearchType="dropDownSearchType"
            :selectTreeNodeData="selectTreeNodeData"
            :treeShowList="treeShowList"
            :breadcrumbsList="breadcrumbsList"
            :optionalLoadTitle="optionalLoadTitle"
            :searchDropdownData="searchDropdownData"
            :alreadySelectNodeData="alreadySelectNodeData"
            @selectFocus="selectFocus"
            @selectInput="selectInput"
            @handlerAllTreeNodeClick="handlerAllTreeNodeClick"
            @handlerTreeNodeClick="handlerTreeNodeClick"
            @treeNodeNextLevelClick="treeNodeNextLevelClick"
            @handlerDelTreeNode="handlerDelTreeNode"
            @changeTreeListLevel="changeTreeListLevel"
            @handlerSetUser = "handlerSetUser"
        ></tree-com>
        <Submit
            @determine="determine"
            @cancel="cancel"
        />
    </div>
</template>
<script>
import DialogTitle from "../components/Title/DialogTitle.vue";
import TreeCom from "./TreeCom.vue";
import Submit from "../components/Submit/index.vue";

export default {
    name: "TreeShapeView",
    components: {
        DialogTitle,
        TreeCom,
        Submit,
    },
    props: {
        allCheck: Boolean,
        multiple: Boolean,
        treeShowList: Array,
        selectTreeNodeData: Array,
        breadcrumbsList: Array,
        optionalLoadTitle: String,
        dropDownSearchType: Boolean,
        searchDropdownData: Array,
        alreadySelectNodeData:Array,
        title: String,
        checkUserOnly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        checkAll: {
            type: Boolean,
            default: () => {
                return false;
            },
        }
    },
    methods: {
        selectFocus () {
            this.$emit('selectFocus')
        },
        selectInput (inputVal) {
            this.$emit('selectInput', inputVal)
        },
        handlerAllTreeNodeClick (data) {
            this.$emit("handlerAllTreeNodeClick", data)
        },
        handlerTreeNodeClick(data) {
            this.$emit("handlerTreeNodeClick", data);
        },
        treeNodeNextLevelClick (data) {
            this.$emit("treeNodeNextLevelClick", data);
        },
        handlerDelTreeNode (data) {
            this.$emit("handlerDelTreeNode", data);
        },
        changeTreeListLevel (data) {
            this.$emit('changeTreeListLevel', data)
        },
        closeSearchPullDown () {
            this.$emit('closeSearchPullDown')
        },
        determine() {
            this.$emit('determine')
        },
        cancel() {
            this.closeDialog()
        },
        closeDialog() {
            this.$emit('closeDialog')
        },
        handlerSetUser(data){
            this.$emit("handlerSetUser",data)
        }
    },
};
</script>
<style lang="scss" scoped>
.tree-shape {
    position: relative;
   //margin: 150px auto;
    width: 700px;
    //height: 560px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
}
</style>
