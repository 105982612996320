<!--
 * @Author: wangmengyao wang_my991127@163.com
 * @Date: 2023-09-01 08:48:17
 * @LastEditors: wangmengyao wang_my991127@163.com
 * @LastEditTime: 2023-09-05 15:17:14
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\TreeShape\src\TreeShapeView\TreeCom.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="tree-com">
        <Optional
            :allCheck="allCheck"
            :multiple="multiple"
            :checkUserOnly="checkUserOnly"
            :checkAll="checkAll"
            :dropDownSearchType="dropDownSearchType"
            :treeShowList="treeShowList"
            :breadcrumbsList="breadcrumbsList"
            :optionalLoadTitle="optionalLoadTitle"
            :searchDropdownData="searchDropdownData"
            :searchOptionalLoadTitle="searchOptionalLoadTitle"
            @selectFocus="selectFocus"
            @selectInput="selectInput"
            @handlerAllTreeNodeClick="handlerAllTreeNodeClick"
            @handlerTreeNodeClick="handlerTreeNodeClick"
            @treeNodeNextLevelClick="treeNodeNextLevelClick"
            @changeTreeListLevel="changeTreeListLevel"
        />
        <Selected :alreadySelectNodeData="alreadySelectNodeData" :selectTreeNodeData="selectTreeNodeData" @handlerSetUser="handlerSetUser" @handlerDelTreeNode="handlerDelTreeNode"/>
    </div>
</template>
<script>
import Optional from "../components/Optional/index.vue";
import Selected from "../components/Selected/index.vue";
export default {
    name: "TreeCom",
    components: {
        Optional,
        Selected,
    },
    props: {
        allCheck: Boolean,
        multiple: Boolean,
        dropDownSearchType: Boolean,
        treeShowList: Array,
        selectTreeNodeData: Array,
        breadcrumbsList: Array,
        optionalLoadTitle: String,
        searchDropdownData: Array,
        searchOptionalLoadTitle: String,
        alreadySelectNodeData:Array,
        checkUserOnly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        checkAll: {
            type: Boolean,
            default: () => {
                return false;
            },
        }
    },
    methods: {
        selectFocus () {
            this.$emit('selectFocus')
        },
        selectInput (inputVal) {
            this.$emit('selectInput', inputVal)
        },
        handlerAllTreeNodeClick (data) {
            this.$emit("handlerAllTreeNodeClick", data)
        },
        handlerTreeNodeClick(data) {
            this.$emit("handlerTreeNodeClick", data);
        },
        treeNodeNextLevelClick (data) {
            this.$emit("treeNodeNextLevelClick", data);
        },
        handlerDelTreeNode (data) {
            this.$emit("handlerDelTreeNode", data);
        },
        changeTreeListLevel (data) {
            this.$emit("changeTreeListLevel", data)
        },
        handlerSetUser(data){
            console.log(data,'---data---')
            this.$emit("handlerSetUser",data
            )
        }
    },
};
</script>
<style lang="scss" scoped>
.tree-com {
    display: flex;
    justify-content: center;
}
</style>
